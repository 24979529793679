import { Link, useParams } from '@remix-run/react';
import { Button } from './ui/button';
import { LogOutIcon } from 'lucide-react';
import classNames from 'classnames';

interface Params {
  alwaysShowText?: boolean;
  className?: string;
}

export function ButtonLogout({ alwaysShowText = false, className }: Params) {
  const { orgSlug } = useParams();

  return (
    <Link to={orgSlug ? `/${orgSlug}/logout` : '/logout'}>
      <Button className={classNames('gap-2', className)} size="sm">
        <span className={alwaysShowText ? '' : 'hidden md:block'}>Logout</span>
        <LogOutIcon className="h-4 w-4" />
      </Button>
    </Link>
  );
}
